import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/store/modules/auth";
import { Actions, Getters, Modules, Mutations, RootState } from "@/store/types";
import { AuthMutations } from "@/store/modules/auth/types";
import { tenant } from "@/store/modules/tenant";
import { toast } from "@/store/modules/toast";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    loading: false,
    error: null,
    appError: null,
    updateAvailable: false,
    serviceWorkerRegistration: null,
  },
  getters: {
    [Getters.LOADING]: (state) => state.loading,
    [Getters.ERROR]: (state) => state.error,
    [Getters.APP_ERROR]: (state) => state.appError,
    [Getters.SYSTEM_ERROR]: (state) => state.appError && state.appError.isSystemError,
    [Getters.UPDATE_AVAILABLE]: (state) => state.updateAvailable,
    [Getters.SERVICE_WORKER_REGISTRATION]: (state) => state.serviceWorkerRegistration,
  },
  mutations: {
    [Mutations.SET_LOADING](state, loading) {
      state.loading = loading;
    },
    [Mutations.SET_ERROR](state, error) {
      state.error = error;
    },
    [Mutations.SET_APP_ERROR](state, error) {
      state.appError = error;
    },
    [Mutations.SET_SERVICE_WORKER_REGISTRATION](state, registration: ServiceWorkerRegistration | null) {
      state.serviceWorkerRegistration = registration;
    },
    [Mutations.SET_UPDATE_AVAILABLE](state, update: boolean) {
      state.updateAvailable = update;
    },
  },
  actions: {
    [Actions.RESET_ERROR]({ commit }) {
      commit(Mutations.SET_ERROR, null);
    },
    [Actions.RESET_APP_ERROR]({ commit }) {
      commit(Mutations.SET_APP_ERROR, null);
    },
    [Actions.SET_SERVER_ERROR]({ commit }, error) {
      commit(Mutations.SET_APP_ERROR, error);
      commit(Modules.AUTH + "/" + AuthMutations.SET, null);
    },
    [Actions.SET_SERVICE_WORKER_REGISTRATION]({ commit }, registration: ServiceWorkerRegistration | null) {
      commit(Mutations.SET_SERVICE_WORKER_REGISTRATION, registration);
    },
    [Actions.INIT_UPDATE]({ commit }) {
      commit(Mutations.SET_UPDATE_AVAILABLE, true);
    },
  },
  modules: {
    [Modules.AUTH]: auth,
    [Modules.TENANT]: tenant,
    [Modules.TOAST]: toast,
  },
});
