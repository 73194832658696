import { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import { AuthGate, CanGate, GuestGate, TenantGate } from "@/common/Gates";

export const routes: Array<RouteConfig> = [
  {
    path: "",
    redirect: { name: "home" },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/403",
    name: "forbidden",
    component: () => import(/* webpackChunkName: "common" */ "@/views/Forbidden.vue"),
  },
  {
    path: "/500",
    name: "server-error",
    component: () => import(/* webpackChunkName: "common" */ "@/views/ServerError.vue"),
  },
  {
    path: "/account/login",
    name: "account.login",
    component: () => import(/* webpackChunkName: "account" */ "@/views/account/Login.vue"),
    meta: { gates: [new GuestGate()] },
  },
  {
    path: "/account/sessions",
    name: "account.sessions",
    component: () => import(/* webpackChunkName: "account" */ "@/views/account/Sessions.vue"),
    meta: { gates: [new AuthGate()] },
  },
  {
    path: "/account/profile",
    name: "account.profile",
    component: () => import(/* webpackChunkName: "account" */ "@/views/account/Profile.vue"),
    meta: { gates: [new AuthGate()] },
  },
  {
    path: "/account/tenant",
    name: "account.tenant",
    component: () => import(/* webpackChunkName: "account" */ "@/views/account/ChooseTenant.vue"),
    meta: { gates: [new AuthGate()] },
  },
  {
    path: "/users",
    name: "users.index",
    component: () => import(/* webpackChunkName: "users" */ "@/views/users/UserIndex.vue"),
    meta: { gates: [new CanGate("viewAny", "users")] },
  },
  {
    path: "/users/:id",
    name: "users.show",
    props: true,
    component: () => import(/* webpackChunkName: "users" */ "@/views/users/UserShow.vue"),
    meta: { gates: [new CanGate("viewAny", "users")] },
  },
  {
    path: "/assets",
    name: "assets.index",
    component: () => import(/* webpackChunkName: "assets" */ "@/views/assets/AssetIndex.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "assets")] },
  },
  {
    path: "/assets/:id",
    name: "assets.show",
    props: true,
    component: () => import(/* webpackChunkName: "assets" */ "@/views/assets/AssetShow.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "assets")] },
  },
  {
    path: "/asset-categories",
    name: "assetCategories.index",
    component: () => import(/* webpackChunkName: "assets" */ "@/views/assets/AssetCategoryIndex.vue"),
    meta: {
      gates: [new TenantGate(), new CanGate("viewAny", "assetCategories")],
    },
  },
  {
    path: "/asset-categories/:id",
    name: "assetCategories.show",
    props: true,
    component: () => import(/* webpackChunkName: "assets" */ "@/views/assets/AssetCategoryShow.vue"),
    meta: {
      gates: [new TenantGate(), new CanGate("viewAny", "assetCategories")],
    },
  },
  {
    path: "/storage-places",
    name: "storagePlaces.index",
    component: () => import(/* webpackChunkName: "storagePlaces" */ "@/views/storagePlace/StoragePlaceIndex.vue"),
    meta: {
      gates: [new TenantGate(), new CanGate("viewAny", "storagePlaces")],
    },
  },
  {
    path: "/storage-places/:id",
    name: "storagePlaces.show",
    props: true,
    component: () => import(/* webpackChunkName: "storagePlaces" */ "@/views/storagePlace/StoragePlaceShow.vue"),
    meta: {
      gates: [new TenantGate(), new CanGate("viewAny", "storagePlaces")],
    },
  },
  {
    path: "/locations",
    name: "locations.index",
    component: () => import(/* webpackChunkName: "locations" */ "@/views/locations/LocationIndex.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "locations")] },
  },
  {
    path: "/locations/:id",
    name: "locations.show",
    props: true,
    component: () => import(/* webpackChunkName: "locations" */ "@/views/locations/LocationShow.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "locations")] },
  },
  {
    path: "/departments/:id",
    name: "departments.show",
    props: true,
    component: () => import(/* webpackChunkName: "departments" */ "@/views/departments/DepartmentShow.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "departments")] },
  },
  {
    path: "/makers",
    name: "makers.index",
    props: true,
    component: () => import(/* webpackChunkName: "makers" */ "@/views/makers/MakerIndex.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "makers")] },
  },
  {
    path: "/makers/:id",
    name: "makers.show",
    props: true,
    component: () => import(/* webpackChunkName: "makers" */ "@/views/makers/MakerShow.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "makers")] },
  },
  {
    path: "/owners",
    name: "owners.index",
    props: true,
    component: () => import(/* webpackChunkName: "owners" */ "@/views/owners/OwnerIndex.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "owners")] },
  },
  {
    path: "/owners/:id",
    name: "owners.show",
    props: true,
    component: () => import(/* webpackChunkName: "owners" */ "@/views/owners/OwnerShow.vue"),
    meta: { gates: [new TenantGate(), new CanGate("viewAny", "owners")] },
  },
  {
    path: "/tenants",
    name: "tenants.index",
    component: () => import(/* webpackChunkName: "tenants" */ "@/views/tenants/TenantIndex.vue"),
    meta: { gates: [new CanGate("viewAny", "tenants")] },
  },
  {
    path: "/tenants/:id",
    name: "tenants.show",
    props: true,
    component: () => import(/* webpackChunkName: "tenants" */ "@/views/tenants/TenantShow.vue"),
    meta: { gates: [new CanGate("viewAny", "tenants")] },
  },
  {
    path: "*",
    name: "notfound",
    component: () => import(/* webpackChunkName: "common" */ "@/views/NotFound.vue"),
  },
];

if (window.configuration.FEATURES.RESET) {
  routes.push({
    path: "/account/forgotten",
    name: "account.forgotten",
    component: () => import(/* webpackChunkName: "account" */ "@/views/account/Forgotten.vue"),
    meta: { gates: [new GuestGate()] },
  });
  routes.push({
    path: "/reset-password/:token",
    props: true,
    name: "account.reset",
    component: () => import(/* webpackChunkName: "account" */ "@/views/account/ResetPassword.vue"),
    meta: { gates: [new GuestGate()] },
  });
}
