export enum AuthGetters {
  USER = "user",
  LOADING = "loading",
  LOGGED_IN = "loggedIn",
}

export enum AuthMutations {
  SET = "set",
  SET_LOADING = "setLoading",
}

export enum AuthActions {
  LOGIN = "login",
  LOGOUT = "logout",
  FETCH = "fetch",
}
