import { LocaleMessages } from "vue-i18n";

export const Messages: LocaleMessages = {
  app: {
    error: "Fehler bei Zugriff auf Server",
    ignore: "Ignorieren",
    reload: "Neuladen",
    backHome: "Zur Startseite",
    back: "Zurück",
    404: {
      title: "Nichts gefunden",
      subtitle: "Der Link den Sie aufgerufen haben, konnte nicht gefunden werden",
    },
    403: {
      title: "Zugriff verweigert!",
      subtitle: "Auf diese Seite haben Sie keinen Zugriff",
    },
    500: {
      title: "Server Problem",
      subtitle: "Es ist ein Problem mit dem Server aufgetreten, die Datenverbindung kann nicht hergestellt werden.",
      subtitle2: "Bitte versuchen Sie es später erneut.",
      reload: "Anwendung neustarten",
    },
    welcome: "Willkommen",
    home: "Home",
    update: {
      available: "Es ist ein Update verfügbar.",
      activate: "Update aktivieren",
    },
  },
  common: {
    notAvailable: "Nicht verfügbar",
    none: "Keine",
    noneFound: "Keine gefunden",
    notExistent: "Nicht vorhanden",
    notSet: "Nicht gesetzt",
    search: "Suche",
    searchHint: "Mindestens drei Zeichen eingeben",
    never: "Niemals",
    image: "Bild",
    upload: "Hochladen",
    newImage: "Neues Bild",
    save: "Speichern",
    saved: "Gespeichert",
    newItem: "Neues Element",
    cancel: "Abbrechen",
    updateImage: "Bild aktualisieren? (Entfernt das Bild wenn kein neues ausgewählt wird)",
    yes: "Ja",
    no: "Nein",
    today: "Heute",
    total: "Gesamt",
    actions: "Aktionen",
    loading: "Lade",
    new: "Neu",
    store: "Einlagern",
    deliver: "Liefern",
    dropFile: "Datei hier ablegen oder unten klicken",
    delete: "Löschen",
    deleted: "Gelöscht",
    remove: "Entfernen",
    edit: "Bearbeiten",
    changelog: "Änderungsverlauf",
  },
  toasts: {
    saved: 'Eintrag: "{name}" wurde gespeichert',
    savedErrors: "Speichern nicht möglich - Fehlerhafte Eingaben",
    deleted: "Eintrag wurde gelöscht",
    tenants: {
      selected: "Tenant: {name} wurde ausgewählt",
      selectedNull: "Tenant Auswahl aufgehoben",
    },
    auth: {
      loggedIn: "Erfolgreich eingeloggt",
      loggedOut: "Erfolgreich ausgeloggt",
    },
  },
  nav: {
    home: "Home",
    about: "Über",
    users: "Benutzer",
    logout: "Ausloggen",
    sessions: "Sitzungen",
    profile: "Benutzerprofil",
    assetCategories: "Asset Kategorien",
    storagePlaces: "Lagerorte",
    locations: "Standorte",
    assets: "Assets",
    tenant: "Tenant auswählen",
    tenants: "Tenants",
    owners: "Anwender",
    makers: "Hersteller",
  },
  attrs: {
    id: "ID",
    name: "Name",
    date: "Datum",
    created_at: "Erstellt",
    updated_at: "Aktualisiert",
    asset_category: "Kategorie",
    category: "Kategorie",
    categories: "Kategorien",
    email: "E-Mail",
    email_confirm: "E-Mail Bestätigung",
    firstname: "Vorname",
    lastname: "Nachname",
    location: "Standort",
    department: "Abteilung",
    position: "Position",
    hours: "Stunden/Woche",
    days: "Tage/Woche",
    calendarWeek: "KW {week}",
    contract: "Vertrag",
    startDate: "Start-Datum",
    endDate: "Ende-Datum",
    user: "Benutzer",
    description: "Beschreibung",
    image: "Bild",
    imageActionDelete: "Bild löschen",
    imageActionUpdate: "Bild aktualisieren",
    password: "Passwort",
    address: "Anschrift",
    postcode: "Postleitzahl",
    town: "Stadt",
    legal_name: "Firmierung",
    serial: "Seriennummer",
    model: "Model",
    storagePlace: "Lagerort",
    storage_place: "Lagerort",
    owner: "Anwender",
    state: "Status",
    purchased_at: "Kaufdatum",
    retired_at: "Ausgeschieden am",
    is_admin: "Admin",
    is_readonly: "Nur Leserechte",
    notifications_enabled: "Benachrichtigungen aktivieren",
    filename: "Dateiname",
    file: "Datei",
    maker: "Hersteller",
  },
  login: {
    title: "Einloggen",
    email: "E-Mail",
    passwordConfirm: "Passwort bestätigen",
    deviceName: "Geräte Name",
    sessionTitle: "Sitzungen",
    removeSession: "Entfernen",
    forgotten: {
      title: "Password vergessen",
      submit: "Neues Password anfordern",
      successTitle: "Passwort angefordert",
      successMessage:
        "Ihnen wurde ein Link per Mail geschickt. Bitte prüfen Sie ihr Postfach zeitnah, da dieser zeitlich begrenzt ist.",
    },
    reset: {
      title: "Neues Passwort wählen",
      subTitle: "Bitte geben Sie ihre E-Mail und das gewünschte Password ein.",
      submit: "Neues Passwort setzen",
      successTitle: "Passwort wurde zurückgesetzt.",
      successMessage: "Ihr Kennwort wurde zurückgesetzt. Sie können sich nun einloggen.",
    },
    failed: {
      401: "Ihr Login ist abgelaufen oder nicht mehr gültig. Bitte erneut einloggen",
    },
    profile: {
      title: "Benutzerprofil aktualisieren",
      emailHeader: "E-Mail Adresse angeben um diese zu aktualisieren.",
      emailHint: "Nur wenn die E-Mail angegeben wird, wird diese geändert!",
      emailHintConfirm: "Bitte E-Mail bestätigen um diese zu ändern.",
      passwordHeader: "Passwort angeben um dieses zu aktualisieren.",
      success: "Ihr Profil wurde erfolgreich aktualisiert.",
      notificationHint: "Sie bekommen für alle Umbuchungen an Assets eine Benachrichtigung",
    },
    password: {
      title: "Passwort aktualisieren",
      currentPassword: "Aktuelles Passwort",
      newPassword: "Neues Passwort",
      newPasswordConfirm: "Neues Passwort bestätigen",
      success: "Ihr Passwort wurde erfolgreich aktualisiert.",
      logoutSessions: "Alle anderen Sitzungen abmelden.",
      loggedOut: "Alle anderen Sitzungen wurden abgemeldet.",
    },
    tenant: {
      title: "Tenant Auswahl",
      submit: "Auswählen",
      refresh: "Verfügbare Tenants abrufen",
      fetched: "Verfügbare Tenants wurden abgerufen",
    },
  },
  users: {
    title: "Benutzer",
    show: "{name}",
    new: "Neuen Benutzer anlegen",
    edit: 'Benutzer "{name}" bearbeiten',
    delete: 'Benutzer "{name}" wirklich löschen?',
    deleteText: "Dies entfernt den Benutzer vollständig aus dem System!",
    permissions: "Berechtigungen",
    notificationHint: "Bekommt für alle Umbuchungen im System eine Benachrichtigung",
    search: {
      title: "Benutzersuche",
      field: "Benutzername",
      noData: "Keine Benutzer gefunden, bitte Suche anpassen",
    },
  },
  tenants: {
    title: "Tenants",
    show: "{name}",
    new: "Neuen Tenant anlegen",
    edit: 'Tenant "{name}" bearbeiten',
    delete: 'Tenant "{name}" wirklich löschen?',
    deleteText: "Dies entfernt den Tenant vollständig aus dem System!",
    users: {
      delete: "Zugriff von Tenant entfernen",
      deleteText: "Benutzer {name} den Zugriff auf diesen Tenant komplett entfernen?",
    },
  },
  assetCategories: {
    title: "Asset Kategorien",
    show: "{name}",
    new: "Neue Kategorie anlegen",
    edit: 'Kategorie "{name}" bearbeiten',
    delete: 'Kategorie "{name}" wirklich löschen?',
    deleteText: "Dies entfernt die Kategorie vollständig aus dem System!",
  },
  assetDocuments: {
    title: 'Dokumente von "{asset}"',
    show: "{name}",
    new: "Neues Dokument anlegen",
    delete: 'Dokument "{name}" wirklich löschen?',
    deleteText: "Dies entfernt die Kategorie vollständig aus dem System!",
    tooBig: "Die Datei darf maximal 10240 Kilobytes groß sein!",
  },
  assets: {
    title: "Assets",
    titleWithName: "Assets von {name}",
    show: "{name}",
    new: "Neues Asset anlegen",
    edit: 'Asset "{name}" bearbeiten',
    delete: 'Asset "{name}" wirklich löschen?',
    deleteText: "Dies entfernt das Asset vollständig aus dem System!",
    retire: 'Assets "{name}" wirklich archivieren?',
    retireText: "Dies markiert das Asset als archiviert und deaktiviert sämtliche Änderungsfunktionen am dem Asset.",
    retireTooltip: "Asset archivieren",
    selectLocation: "Standort auswählen um Abteilung anzugeben",
    selectDepartment: "Abteilung auswählen um Benutzer anzugeben",
    store: "Einlagern",
    deliver: "Ausliefern",
    storeAsset: "Asset einlagern",
    deliverAsset: "Asset ausliefern",
    documents: "Dokumente",
    changeHistory: "Änderungsverlauf",
    states: {
      stored: "Eingelagert",
      active: "Aktiv",
      rtired: "Pensioniert",
      stale: "Nicht zugewiesen",
      unknwn: "Unbekannt",
    },
  },
  history: {
    title: "Änderungsverlauf von {name}",
    noEntries: "Noch keine Einträge vorhanden",
    load: "Änderungsverlauf laden",
    loadMore: "Mehr anzeigen",
  },
  storagePlaces: {
    title: "Lagerort",
    show: "{name}",
    new: "Neuen Lagerort anlegen",
    edit: 'Lagerort "{name}" bearbeiten',
    delete: 'Lagerort "{name}" wirklich löschen?',
    deleteText: "Dies entfernt den Lagerort vollständig aus dem System!",
  },
  locations: {
    title: "Standorte",
    show: "{name}",
    new: "Neuen Standort anlegen",
    edit: 'Standort "{name}" bearbeiten',
    delete: 'Standort "{name}" wirklich löschen?',
    deleteText: "Dies entfernt dem Standort vollständig aus dem System!",
  },
  departments: {
    title: "Abteilungen von {name}",
    show: "{name}",
    new: "Neue Abteilung anlegen",
    edit: 'Abteilung "{name}" bearbeiten',
    delete: 'Abteilung "{name}" wirklich löschen?',
    deleteText: "Dies entfernt die Abteilung vollständig aus dem System!",
  },
  owners: {
    titleWithName: "Anwender von {name}",
    title: "Anwender",
    show: "{name}",
    new: "Neuen Anwender anlegen",
    edit: 'Anwender "{name}" bearbeiten',
    delete: 'Anwender "{name}" wirklich löschen?',
    deleteText: "Dies entfernt den Anwender vollständig aus dem System!",
    notificationHint: "Bekommt für alle Änderungen seiner Assets eine Benachrichtigung",
    departmentHeader: "Hiermit kann der Benutzer in eine andere Abteilung verschoben werden",
    transferAssets: "Bei Änderung der Abteilung: Gewünschte Assets zur Übertragung auswählen",
  },
  makers: {
    title: "Hersteller",
    show: "{name}",
    new: "Neuer Hersteller anlegen",
    edit: 'Hersteller "{name}" bearbeiten',
    delete: 'Hersteller "{name}" wirklich löschen?',
    deleteText: "Dies entfernt den Hersteller vollständig aus dem System!",
  },
};
export const DateTimeFormats: any = {
  short: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  },
  time: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  long: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
};
