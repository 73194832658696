import { EndpointWithIndex } from "@/api/Endpoints/Endpoint";
import { Tenant } from "@/api/types/Tenant";
import { ApiConfig, CollectionResponse } from "@/api/types";
import { History } from "@/api/types/History";

export class TenantEndpointClass extends EndpointWithIndex<Tenant> {
  constructor() {
    super("tenants");
    this.cast = (x) => new Tenant(x);
    this.castColl = (a) => a.map((x) => new Tenant(x));
  }

  history(
    tenant: Tenant,
    params: Record<string, string> = {},
    config: ApiConfig = {},
    all = false
  ): Promise<CollectionResponse<History>> {
    return this.indexFunc(`${this.resource}/${tenant.id}/history`, params, config, all);
  }
}

export const TenantEndpoint = new TenantEndpointClass();
