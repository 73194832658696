export interface IModelPermission {
  viewAny?: boolean;
  view?: boolean;
  update?: boolean;
  create?: boolean;
  delete?: boolean;
  retire?: boolean;
  history?: boolean;

  [index: string]: boolean | undefined;
}

export interface IUserPermission {
  users?: IModelPermission;

  can(ability: string, model: string): boolean;
}

export class UserPermission implements IUserPermission {
  users?: IModelPermission;

  // eslint-disable-next-line @typescript-eslint/ban-types
  [index: string]: IModelPermission | undefined | Function;

  constructor(permissions: IUserPermission) {
    Object.assign(this, permissions);
  }

  can(ability: string, model: string): boolean {
    if (this[model]) {
      const m = this[model] as IModelPermission;
      return !!m[ability];
    }
    return false;
  }
}
