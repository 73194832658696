import { User } from "@/api/types/User";
import { UserPermission } from "@/api/types/Permissions";
import { Modules } from "@/store/types";
import { AuthActions, AuthGetters } from "@/store/modules/auth/types";
import Vue from "vue";
import { Credentials } from "@/store/modules/auth";
import { LoginResponse } from "@/api/Endpoints/Login";

export default Vue.extend({
  computed: {
    loggedIn(): boolean {
      return this.$store.getters[Modules.AUTH + "/" + AuthGetters.LOGGED_IN];
    },
    authUser(): User {
      return this.$store.state[Modules.AUTH][AuthGetters.USER];
    },
    authLoading(): boolean {
      return this.$store.getters[Modules.AUTH + "/" + AuthGetters.LOADING];
    },
    authPermissions(): UserPermission {
      return this.authUser?.meta?.global_permissions as UserPermission;
    },
  },
  methods: {
    authFetchUser() {
      return this.$store.dispatch(Modules.AUTH + "/" + AuthActions.FETCH);
    },
    authLogout(): Promise<void> {
      return this.$store.dispatch(Modules.AUTH + "/" + AuthActions.LOGOUT);
    },
    authLogin(credentials: Credentials): Promise<LoginResponse> {
      return this.$store.dispatch(Modules.AUTH + "/" + AuthActions.LOGIN, credentials);
    },
  },
});
