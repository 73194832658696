
import AppError from "@/components/app/AppError.vue";
import LoginBar from "@/components/app/LoginBar.vue";
import AuthStateMixin from "@/mixins/store/AuthStateMixin";
import { CanGate, Gate } from "@/common/Gates";
import NotFound from "@/views/NotFound.vue";
import Forbidden from "@/views/Forbidden.vue";
import RootStateMixin from "@/mixins/store/RootStateMixin";
import { Modules } from "@/store/types";
import { AuthMutations } from "@/store/modules/auth/types";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import TenantStateMixin from "@/mixins/store/TenantStateMixin";
import NavChips from "@/components/app/NavChips.vue";
import UpdateHandler from "@/components/app/UpdateHandler.vue";
import Toasts from "@/components/app/Toasts.vue";

@Component({
  components: {
    Toasts,
    UpdateHandler,
    NavChips,
    Forbidden,
    NotFound,
    LoginBar,
    AppError,
  },
  metaInfo() {
    return {
      title: this.$t("app.home") as string,
      // eslint-disable-next-line
      // @ts-ignore
      titleTemplate: this.selectedTenant
        ? // eslint-disable-next-line
          // @ts-ignore
          "%s | " + this.selectedTenant.name + " | Assets by CarNetiX"
        : "%s | Assets by CarNetiX",
    };
  },
})
export default class App extends mixins(AuthStateMixin, RootStateMixin, TenantStateMixin) {
  drawer = false;
  loading = true;
  standalone = false;
  links = [
    {
      href: { name: "home" },
      text: "nav.home",
      icon: "mdi-home",
      gateResult: true,
    },
    {
      href: { name: "about" },
      text: "nav.about",
      icon: "mdi-information-variant",
      gate: new CanGate("view", "customers"),
      gateResult: false,
    },
    {
      href: { name: "assets.index" },
      text: "nav.assets",
      icon: "mdi-tablet-cellphone",
      gate: new CanGate("viewAny", "assets"),
      gateResult: false,
    },
    {
      href: { name: "assetCategories.index" },
      text: "nav.assetCategories",
      icon: "mdi-shape",
      gate: new CanGate("viewAny", "assetCategories"),
      gateResult: false,
    },
    {
      href: { name: "locations.index" },
      text: "nav.locations",
      icon: "mdi-office-building",
      gate: new CanGate("viewAny", "locations"),
      gateResult: false,
    },
    {
      href: { name: "owners.index" },
      text: "nav.owners",
      icon: "mdi-account-convert",
      gate: new CanGate("viewAny", "owners"),
      gateResult: false,
    },
    {
      href: { name: "storagePlaces.index" },
      text: "nav.storagePlaces",
      icon: "mdi-library-shelves",
      gate: new CanGate("viewAny", "storagePlaces"),
      gateResult: false,
    },
    {
      href: { name: "makers.index" },
      text: "nav.makers",
      icon: "mdi-handshake",
      gate: new CanGate("viewAny", "makers"),
      gateResult: false,
    },
    {
      href: { name: "users.index" },
      text: "nav.users",
      icon: "mdi-account",
      gate: new CanGate("viewAny", "users"),
      gateResult: false,
    },
    {
      href: { name: "tenants.index" },
      text: "nav.tenants",
      icon: "mdi-office-building-marker",
      gate: new CanGate("viewAny", "tenants"),
      gateResult: false,
    },
  ];

  get appColor(): string {
    return this.systemError ? "error" : "secondary";
  }

  back(): void {
    window.history.back();
  }

  forward(): void {
    window.history.forward();
  }

  gateCheck(gate: CanGate | undefined): boolean {
    if (gate instanceof Gate) {
      return gate.isAllowed();
    }
    return true;
  }

  updateGateResults(): void {
    for (let i = 0; i < this.links.length; i++) {
      this.links[i].gateResult = this.gateCheck(this.links[i].gate);
    }
  }

  mounted(): void {
    this.standalone = window.matchMedia("(display-mode: standalone)").matches;
    if (this.loggedIn) {
      this.authFetchUser()
        .then(() => {
          this.tenantFetch()
            .catch((e) => {
              if (e.isSystemError) {
                this.$store.commit(Modules.AUTH + "/" + AuthMutations.SET, null);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((e) => {
          if (e.isSystemError) {
            this.$store.commit(Modules.AUTH + "/" + AuthMutations.SET, null);
          }
          this.loading = false;
        });
    } else {
      this.updateGateResults();
      this.loading = false;
    }
  }

  @Watch("authUser")
  onAuthUserChanged(): void {
    this.updateGateResults();
  }

  @Watch("$route")
  onRouteChanged(): void {
    if (this.error) {
      this.resetError();
    }
  }

  reloadApp(): void {
    window.location.reload();
  }
}
