import { Credentials } from "@/store/modules/auth";
import { Api } from "@/api";
import { IUser, User } from "@/api/types/User";
import { IModel } from "@/api/types/Model";
import { ApiConfig, CollectionResponse } from "@/api/types";
import { Tenant } from "@/api/types/Tenant";

export interface ISession extends IModel {
  name: string;
  // eslint-disable-next-line camelcase
  last_user_at?: Date;
  abilities?: Array<string>;
}

export interface ITokenResponse {
  token: string;
  session?: ISession;
}

export interface ILoginResponse extends ITokenResponse {
  user: IUser;
}

export interface IUpdateCredentials {
  // eslint-disable-next-line camelcase
  current_password: string;
  // eslint-disable-next-line camelcase
  new_password: string;
  // eslint-disable-next-line camelcase
  new_password_confirmation: string;
  // eslint-disable-next-line camelcase
  logout_sessions: boolean;
}

export interface IUpdateProfile {
  name: string;
  email?: string;
  // eslint-disable-next-line camelcase
  email_confirmation?: string;
  // eslint-disable-next-line camelcase
  notifications_enabled?: boolean;
}

export class LoginResponse implements ILoginResponse {
  token!: string;
  user!: User;

  constructor(input: ILoginResponse) {
    Object.assign(this, input);
    this.user = new User(input.user);
  }

  test(): string[] {
    return ["login", "yey"];
  }
}

export class Login {
  login(credentials: Credentials): Promise<LoginResponse> {
    return Api.post<LoginResponse>("login", credentials, {
      cast: (x) => new LoginResponse(x),
    });
  }

  user(): Promise<User> {
    return Api.get<User>("login/user", "", { cast: (x) => new User(x) });
  }

  tenants(params: Record<string, string> = {}): Promise<CollectionResponse<Tenant>> {
    return Api.indexAll<Tenant>("login/tenants", params, {
      castColl: (a) => a.map((x) => new Tenant(x)),
    });
  }

  async update(data: IUpdateProfile, config: ApiConfig = {}): Promise<User> {
    return await Api.update<User>("login", "user", data, {
      cast: (x) => new User(x),
      ...config,
    });
  }

  async password(data: IUpdateCredentials, config: ApiConfig = {}): Promise<User> {
    return await Api.post<User>("login/password", data, {
      cast: (x) => new User(x),
      ...config,
    });
  }

  logout(): Promise<unknown> {
    return Api.get<unknown>("login", "logout");
  }

  async logoutSession(id: number | string): Promise<void> {
    await Api.get<unknown>("login/logout", id);
  }

  sessions(): Promise<CollectionResponse<ISession>> {
    return Api.indexAll<ISession>("login/sessions");
  }
}

export const LoginEndpoint = new Login();
