/* eslint-disable camelcase */
import { IUser, User } from "@/api/types/User";

export interface ITenantSettings {
  is_admin: boolean;
  is_readonly: boolean;
  notifications_enabled: boolean;
  created_at: Date;
  updated_at: Date;
}

export class TenantSettings implements ITenantSettings {
  is_admin = false;
  is_readonly = false;
  notifications_enabled = false;
  created_at!: Date;
  updated_at!: Date;

  constructor(input?: ITenantSettings) {
    Object.assign(this, input);
  }
}

export interface ITenantUser extends IUser {
  tenant_settings: ITenantSettings;
}

export class TenantUser extends User implements ITenantUser {
  tenant_settings = new TenantSettings();

  constructor(input?: ITenantUser) {
    super(input);
    if (input?.tenant_settings) {
      this.tenant_settings = new TenantSettings(input.tenant_settings);
    }
  }
}
