/* eslint-disable camelcase */
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { Modules, RootState } from "@/store/types";
import { TenantActions, TenantGetters, TenantMutations } from "@/store/modules/tenant/types";
import { LoginEndpoint } from "@/api/Endpoints/Login";
import { Api } from "@/api";
import { Tenant } from "@/api/types/Tenant";
import { AuthActions } from "@/store/modules/auth/types";
import { createToast, dispatchToast } from "@/store/modules/toast";

export interface TenantState {
  loading: boolean;
  selected: boolean;
  tenant?: Tenant | null;
  tenants: Array<Tenant>;
}

const state: TenantState = {
  loading: Api.hasTenant(),
  selected: Api.hasTenant(),
  tenant: undefined,
  tenants: [],
};

const getters: GetterTree<TenantState, RootState> = {
  [TenantGetters.LOADING](state) {
    return state.loading;
  },
  [TenantGetters.SELECTED](state) {
    return state.selected;
  },
  [TenantGetters.TENANT](state) {
    return state.tenant;
  },
  [TenantGetters.TENANTS](state) {
    return state.tenants;
  },
};
const mutations: MutationTree<TenantState> = {
  [TenantMutations.SET_LOADING](state, payload: boolean) {
    state.loading = payload;
  },
  [TenantMutations.SET_TENANTS](state, payload: Array<Tenant>) {
    state.tenants = payload;
  },
  [TenantMutations.SET_TENANT](state, payload: Tenant | null) {
    state.tenant = payload;
    state.selected = !!payload;
  },
};
const actions: ActionTree<TenantState, RootState> = {
  async [TenantActions.FETCH]({ commit, dispatch }) {
    commit(TenantMutations.SET_LOADING, true);
    await LoginEndpoint.tenants({ order: "name", by: "asc" })
      .then((res) => {
        commit(TenantMutations.SET_TENANTS, res.data);
        if (res.data.length === 1) {
          dispatch(TenantActions.SELECT, res.data[0]);
        } else {
          const tenant = res.data.find((x) => x.id === Api.getTenant()) ?? null;
          dispatch(TenantActions.SELECT, tenant);
        }
        commit(TenantMutations.SET_LOADING, false);
      })
      .catch(() => {
        dispatch(TenantActions.SELECT, null);
        commit(TenantMutations.SET_LOADING, false);
      });
  },
  async [TenantActions.SELECT]({ commit, dispatch, state }, tenant: Tenant | null) {
    if (tenant === null) {
      Api.removeTenant();
    } else {
      Api.setTenant(tenant);
    }
    const old = state.tenant;
    commit(TenantMutations.SET_TENANT, tenant);
    if (old?.id !== tenant?.id) {
      commit(TenantMutations.SET_LOADING, true);
      await dispatch(Modules.AUTH + "/" + AuthActions.FETCH, null, {
        root: true,
      });
      if (tenant !== null) {
        await dispatchToast(dispatch, createToast("toasts.tenants.selected", { name: tenant?.name }, "success", 2000));
      }
      commit(TenantMutations.SET_LOADING, false);
    }
  },
};

export const tenant: Module<TenantState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
