import { Getters, Mutations } from "@/store/types";
import Vue from "vue";
import { ApiError } from "@/api/ApiError";

export default Vue.extend({
  computed: {
    rootLoading(): boolean {
      return this.$store.getters[Getters.LOADING];
    },
    error(): ApiError {
      return this.$store.getters[Getters.ERROR];
    },
    appError(): ApiError {
      return this.$store.getters[Getters.APP_ERROR];
    },
    systemError(): boolean {
      return this.$store.getters[Getters.SYSTEM_ERROR];
    },
    appUpdateAvailable(): boolean {
      return this.$store.getters[Getters.UPDATE_AVAILABLE];
    },
    appServiceWorkerRegistration(): ServiceWorkerRegistration | null {
      return this.$store.getters[Getters.SERVICE_WORKER_REGISTRATION];
    },
  },
  methods: {
    resetError() {
      this.$store.commit(Mutations.SET_ERROR, null);
    },
    resetAppError() {
      this.$store.commit(Mutations.SET_APP_ERROR, null);
    },
  },
});
