import Vue from "vue";

function limit(value: string, max = 100) {
  if (value !== null && value.length > max) {
    return value.substr(0, max) + "...";
  }
  return value;
}

function stripTags(value: string) {
  return value.replace(/<(?:.|\n)*?>/gm, "");
}

function shortenUuid(value: string) {
  return value.split(/[-]+/).pop();
}

Vue.filter("limit", limit);
Vue.filter("stripTags", stripTags);
Vue.filter("shortenUuid", shortenUuid);
