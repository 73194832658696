export enum ToastGetters {
  TOASTS = "toasts",
}

export enum ToastMutations {
  ADD_TOAST = "addToast",
  REMOVE_TOAST = "closeToast",
}

export enum ToastActions {
  ADD_TOAST = "addToast",
  CLOSE_TOAST = "closeToast",
  START_CLEANUP = "startCleanup",
}
