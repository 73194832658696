import { ApiConfig, CollectionResponse } from "@/api/types";
import { Api } from "@/api";
import { AxiosResponse } from "axios";
import { IModel } from "@/api/types/Model";

export abstract class Endpoint<T> {
  protected resource: string;
  protected cast?: (input: any) => any;
  protected castColl?: (input: Array<any>) => any;

  protected constructor(resource: string) {
    this.resource = resource;
  }

  protected indexFunc<T>(
    resource: string,
    params: Record<string, string> = {},
    config: ApiConfig = {},
    all = false
  ): Promise<CollectionResponse<T>> {
    return all
      ? Api.indexAll(resource, params, { castColl: this.castColl, ...config })
      : Api.index(resource, params, { castColl: this.castColl, ...config });
  }

  protected indexRelationFunc<T>(
    model: IModel,
    resource: string,
    params: Record<string, string> = {},
    config: ApiConfig = {},
    all = false
  ): Promise<CollectionResponse<T>> {
    return this.indexFunc<T>(`${resource}/${model.id}/${this.resource}`, params, config, all);
  }

  get(slug: string | number = "", config: ApiConfig = {}): Promise<T> {
    return Api.get<T>(this.resource, slug, { cast: this.cast, ...config });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  update(slug: string | number, params: any, config: ApiConfig = {}): Promise<T> {
    return Api.update<T>(this.resource, slug, params, {
      cast: this.cast,
      ...config,
    });
  }

  delete(slug: string | number): Promise<AxiosResponse<unknown>> {
    return Api.delete(this.resource, slug);
  }

  getResourceName(): string {
    return this.resource;
  }
}

export abstract class EndpointWithIndex<T> extends Endpoint<T> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  post(params: any, config: ApiConfig = {}): Promise<T> {
    return Api.post<T>(this.resource, params, { cast: this.cast, ...config });
  }

  index(params: Record<string, string> = {}, config: ApiConfig = {}): Promise<CollectionResponse<T>> {
    return this.indexFunc(this.resource, params, config);
  }

  indexAll(params: Record<string, string> = {}, config: ApiConfig = {}): Promise<CollectionResponse<T>> {
    return this.indexFunc(this.resource, params, config, true);
  }
}
