import Component, { mixins } from "vue-class-component";
import { createToast, Toast } from "@/store/modules/toast";
import { Modules } from "@/store/types";
import { ToastActions, ToastGetters } from "@/store/modules/toast/types";

@Component
export default class ToastStateMixin extends mixins() {
  get toasts(): Array<Toast> {
    return this.$store.getters[Modules.TOAST + "/" + ToastGetters.TOASTS];
  }

  async addToast(toast: Toast) {
    await this.$store.dispatch(Modules.TOAST + "/" + ToastActions.ADD_TOAST, toast);
  }

  async addTranslatedToast(translationKey: string, values = {}, color: string | null = null, timeout = 5000) {
    await this.addToast(createToast(translationKey, values, color, timeout));
  }

  async closeToast(toast: Toast) {
    await this.$store.dispatch(Modules.TOAST + "/" + ToastActions.CLOSE_TOAST, toast);
  }

  async startCleanup() {
    await this.$store.dispatch(Modules.TOAST + "/" + ToastActions.START_CLEANUP);
  }
}
