/* eslint-disable camelcase */
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { Modules, RootState } from "@/store/types";
import { User } from "@/api/types/User";
import { AuthActions, AuthGetters, AuthMutations } from "@/store/modules/auth/types";
import { LoginEndpoint } from "@/api/Endpoints/Login";
import { Api } from "@/api";
import { TenantActions } from "@/store/modules/tenant/types";
import { dispatchToast, Toast } from "@/store/modules/toast";
import i18n from "@/plugins/i18n";

export interface AuthState {
  loading: boolean;
  loggedIn: boolean;
  user?: User;
}

export interface Credentials {
  email: string;
  password: string;
  device_name: string;
}

const state: AuthState = {
  loading: Api.isLoggedIn(),
  loggedIn: Api.isLoggedIn(),
  user: undefined,
};

const getters: GetterTree<AuthState, RootState> = {
  [AuthGetters.LOADING](state) {
    return state.loading;
  },
  [AuthGetters.LOGGED_IN](state) {
    return state.loggedIn;
  },
  [AuthGetters.USER](state) {
    return state.user;
  },
};
const mutations: MutationTree<AuthState> = {
  [AuthMutations.SET_LOADING](state, payload: boolean) {
    state.loading = payload;
  },
  [AuthMutations.SET](state, payload: User) {
    state.user = payload;
    state.loggedIn = !!payload;
    state.loading = false;
  },
};
const actions: ActionTree<AuthState, RootState> = {
  async [AuthActions.LOGIN]({ commit, dispatch }, credentials: Credentials) {
    commit(AuthMutations.SET_LOADING, true);
    const loginResponse = await LoginEndpoint.login(credentials).finally(() => {
      commit(AuthMutations.SET_LOADING, false);
    });
    Api.setToken(loginResponse.token);
    commit(AuthMutations.SET, loginResponse.user);
    const toast = new Toast(i18n.t("toasts.auth.loggedIn") as string);
    await dispatchToast(dispatch, toast);
    await dispatch(Modules.TENANT + "/" + TenantActions.FETCH, null, {
      root: true,
    });
  },
  async [AuthActions.LOGOUT]({ commit, dispatch }): Promise<void> {
    commit(AuthMutations.SET_LOADING, true);
    await LoginEndpoint.logout().finally(() => {
      Api.removeToken();
      Api.removeTenant();
      commit(AuthMutations.SET, null);
      commit(AuthMutations.SET_LOADING, false);
      const toast = new Toast(i18n.t("toasts.auth.loggedOut") as string);
      dispatchToast(dispatch, toast);
    });
  },
  async [AuthActions.FETCH]({ commit }) {
    commit(AuthMutations.SET_LOADING, true);
    const user = await LoginEndpoint.user().catch((e) => {
      commit(AuthMutations.SET_LOADING, false);
      throw e;
    });
    commit(AuthMutations.SET, user);
    return user;
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
