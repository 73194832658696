
import AuthStateMixin from "@/mixins/store/AuthStateMixin";
import Component, { mixins } from "vue-class-component";
import TenantStateMixin from "@/mixins/store/TenantStateMixin";

@Component
export default class LoginBar extends mixins(AuthStateMixin, TenantStateMixin) {
  doLogout(): void {
    this.authLogout().then(() => {
      if (this.$route.name === "home") {
        this.$router.go(0);
      } else {
        this.$router.push({ name: "home" });
      }
    });
  }
}
