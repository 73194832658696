import { ApiError } from "@/api/ApiError";

export interface RootState {
  loading: boolean;
  error: ApiError | null;
  appError: ApiError | null;
  updateAvailable: boolean;
  serviceWorkerRegistration: ServiceWorkerRegistration | null;
}

export enum Mutations {
  SET_LOADING = "setLoading",
  SET_ERROR = "setError",
  SET_APP_ERROR = "setAppError",
  SET_UPDATE_AVAILABLE = "setUpdateAvailable",
  SET_SERVICE_WORKER_REGISTRATION = "setServiceWorkerRegistration",
}

export enum Getters {
  LOADING = "loading",
  ERROR = "error",
  APP_ERROR = "appError",
  SYSTEM_ERROR = "systemError",
  UPDATE_AVAILABLE = "updateAvailable",
  SERVICE_WORKER_REGISTRATION = "serviceWorkerRegistration",
}

export enum Actions {
  RESET_ERROR = "resetError",
  RESET_APP_ERROR = "resetAppError",
  SET_SERVER_ERROR = "setServerError",
  INIT_UPDATE = "initUpdate",
  SET_SERVICE_WORKER_REGISTRATION = "setServiceWorkerRegistration",
}

export enum Modules {
  AUTH = "auth",
  TENANT = "tenant",
  TOAST = "toast",
}
