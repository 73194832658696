import Component, { mixins } from "vue-class-component";
import RootStateMixin from "@/mixins/store/RootStateMixin";

@Component
export default class UpdateHandlerMixin extends mixins(RootStateMixin) {
  refreshing = false;

  created(): void {
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  refreshApp(): void {
    if (this.appUpdateAvailable) {
      if (!this.appServiceWorkerRegistration || !this.appServiceWorkerRegistration.waiting) {
        return;
      }
      this.appServiceWorkerRegistration.waiting.postMessage({
        type: "SKIP_WAITING",
      });
    }
  }
}
