import { EndpointWithIndex } from "@/api/Endpoints/Endpoint";
import { Tenant } from "@/api/types/Tenant";
import { TenantUser } from "@/api/types/TenantUser";
import { CollectionResponse } from "@/api/types";
import { Api } from "@/api";

export class TenantUserEndpointClass extends EndpointWithIndex<TenantUser> {
  constructor(tenant: Tenant) {
    super("tenants/" + tenant.id + "/users");
    this.cast = (x) => new TenantUser(x);
    this.castColl = (a) => a.map((x) => new TenantUser(x));
  }

  massUpdate(users: Array<TenantUser>, detach = false): Promise<CollectionResponse<TenantUser>> {
    return Api.patchIndex<TenantUser>(this.resource, { detach, users }, {}, { castColl: this.castColl });
  }
}
