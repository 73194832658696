import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import { router } from "@/router";
import store from "@/store";
import "@/configuration";
import vuetify from "@/plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import Api from "@/api";
import i18n from "@/plugins/i18n";
import "@/plugins/vue-meta";

Vue.config.productionTip = false;
require("@/common/filters");
Api.init();
Vue.mixin({
  methods: {
    getDescendantProp(obj: any, desc: string) {
      const arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift() as string]));
      return obj;
    },
  },
});
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
export const VueRouter = router;
