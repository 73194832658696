import { IModelPermission, IUserPermission } from "@/api/types/Permissions";

export interface IMetaAttribute {
  permissions?: IModelPermission;
  // eslint-disable-next-line camelcase
  global_permissions?: IUserPermission;
  link: string;
}

export function EmptyMetaAttribute(): IMetaAttribute {
  return {
    permissions: {} as IModelPermission,
  } as IMetaAttribute;
}
