import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { Gate } from "@/common/Gates";
import { Modules } from "@/store/types";
import { AuthGetters } from "@/store/modules/auth/types";
import { routes } from "@/router/routes";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
let unwatch: () => void;
router.beforeEach((to, from, next) => {
  const proceed = () => {
    if (unwatch) {
      unwatch();
    }
    if (to.meta && to.meta.gates && to.meta.gates.length > 0) {
      const gate: Gate = to.meta.gates.find((x: Gate) => !x.isAllowed());
      if (gate != null) {
        next(gate.route(to));
        return;
      }
    }
    next();
  };
  if (store.getters[Modules.AUTH + "/" + AuthGetters.LOADING]) {
    unwatch = store.watch(
      (state) => (state as any)[Modules.AUTH][AuthGetters.LOADING],
      (value) => {
        if (value === false) {
          proceed();
        }
      }
    );
  } else {
    proceed();
  }
});
