import { User } from "@/api/types/User";
import store from "@/store";
import { Modules } from "@/store/types";
import { AuthGetters } from "@/store/modules/auth/types";
import { Location, Route } from "vue-router";
import { Api } from "@/api";

declare interface IGate {
  isAllowed(): boolean;

  route(to?: Route): Location;
}

export abstract class Gate implements IGate {
  getUser(): User {
    return store.getters[Modules.AUTH + "/" + AuthGetters.USER];
  }

  abstract isAllowed(): boolean;

  abstract route(to?: Route): Location;
}

export class CanGate extends Gate implements IGate {
  ability: string;
  model: string;

  constructor(ability: string, model: string) {
    super();
    this.ability = ability;
    this.model = model;
  }

  isAllowed(): boolean {
    return !!this.getUser()?.meta?.global_permissions?.can(this.ability, this.model);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  route(to?: Route): Location {
    return { name: "forbidden" };
  }
}

export class AuthGate extends Gate implements IGate {
  isAllowed(): boolean {
    return !!this.getUser();
  }

  route(to?: Route): Location {
    return to ? { name: "account.login", query: { redirect: to.fullPath } } : { name: "account.login" };
  }

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
}

export class GuestGate extends Gate implements IGate {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  isAllowed(): boolean {
    return !this.getUser();
  }

  // eslint-disable-next-line
  route(to?: Route): Location {
    return { name: "home" };
  }
}

export class TenantGate extends Gate implements IGate {
  isAllowed(): boolean {
    return !!this.getUser() && !!Api.getTenant();
  }

  route(to?: Route): Location {
    return to ? { name: "account.tenant", query: { redirect: to.fullPath } } : { name: "account.tenant" };
  }

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
}
