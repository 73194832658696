export enum TenantGetters {
  TENANT = "tenant",
  TENANTS = "tenants",
  LOADING = "loading",
  SELECTED = "selected",
}

export enum TenantMutations {
  SET_LOADING = "setLoading",
  SET_TENANTS = "setTenants",
  SET_TENANT = "setTenant",
}

export enum TenantActions {
  FETCH = "fetch",
  SELECT = "select",
}
