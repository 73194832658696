import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiConfig, ValidationErrors } from "@/api/types";

export interface IApiError {
  status?: number;
  error: Error;
  response?: AxiosResponse<any>;
  config?: ApiConfig;
  validationErrors?: ValidationErrors;
}

export class ApiError implements IApiError {
  status?: number;
  error: Error;
  response?: AxiosResponse<any>;
  config?: ApiConfig;
  validationErrors?: ValidationErrors;
  isSystemError = false;

  constructor(error: Error) {
    this.error = error;
    this.isSystemError = false;
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      this.status = axiosError.response?.status;
      this.response = axiosError.response;
      this.config = axiosError.config;
      if (this.status === 422) {
        this.validationErrors = this.response?.data.errors;
      }
      if (!this.response) {
        this.isSystemError = true;
      }
    } else {
      this.isSystemError = true;
    }
  }

  getMessage(): string {
    if (this.response && this.status) {
      switch (this.status) {
        case 422:
          return this.response.data.message;
        case 401:
          return this.response.data.message;
        case 403:
          return this.response.data && this.response.data.message ? this.response.data.message : "Forbidden";
        case 404:
          return this.response.data && this.response.data.message ? this.response.data.message : "Not found";
        case 405:
          return "Method not allowed";
      }
      if (this.status >= 500) {
        return this.response.data && this.response.data.message ? this.response.data.message : "Api not reachable";
      }
    }
    return this.error.message ? this.error.message : "Unknown Error";
  }
}
