
import RootStateMixin from "@/mixins/store/RootStateMixin";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { ApiError } from "@/api/ApiError";

@Component
export default class AppError extends mixins(RootStateMixin) {
  dialog = false;

  get message(): string {
    return this.appError?.getMessage();
  }

  dismiss(): void {
    this.dialog = false;
    this.resetAppError();
  }

  reload(): void {
    window.location.reload();
  }

  @Watch("appError")
  onAppErrorChanged(newVal: ApiError): void {
    if (newVal && !newVal.isSystemError) {
      this.dialog = true;
    }
  }
}
