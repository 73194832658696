declare interface ConfigurationFeatures {
  RESET: boolean;
  IMAGES: boolean;
}

declare class Configuration {
  API: "/api";
  LOCALE: "de";
  FALLBACK_LOCALE: "de";
  FEATURES: ConfigurationFeatures;
}

declare interface Window {
  configuration: Configuration;
}

const w = window as Window;
w.configuration = w.configuration || new Configuration();
