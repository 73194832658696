/* eslint-disable camelcase */
import { IModel } from "@/api/types/Model";
import { UserPermission } from "@/api/types/Permissions";
import { EmptyMetaAttribute, IMetaAttribute } from "@/api/types/MetaAttribute";
import { Location as LocationRouter } from "vue-router/types/router";
import { GetDefaultSettings, ISettings } from "@/api/types/Settings";
import { Api } from "@/api";
import { CollectionResponse } from "@/api/types/index";
import { History, IHaveHistory } from "@/api/types/History";
import { UserEndpoint } from "@/api/Endpoints/UserEndpoint";

export interface IUser extends IModel, IHaveHistory {
  name: string;
  email: string;
  is_admin: boolean;
  meta: IMetaAttribute;
}

export class User implements IUser {
  created_at!: Date;
  email!: string;
  id!: string;
  name!: string;
  is_admin = false;
  updated_at!: Date;
  meta = EmptyMetaAttribute();
  settings!: ISettings;

  constructor(input?: IUser) {
    if (input) {
      Object.assign(this, input);
      if (input.meta.global_permissions) {
        this.meta.global_permissions = new UserPermission(input.meta.global_permissions);
      }
      if (this.settings == null) {
        this.settings = GetDefaultSettings();
      } else {
        const defaultSettings = GetDefaultSettings();
        const obj = this.settings as any;
        for (const [key, value] of Object.entries(defaultSettings)) {
          if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            obj[key] = value;
          }
        }
        this.settings = obj as ISettings;
      }
    }
  }

  history(params: Record<string, string> = {}, all = false): Promise<CollectionResponse<History>> {
    return UserEndpoint.history(this, params, {}, all);
  }

  historyName(): string {
    return this.name;
  }

  getLink(): LocationRouter {
    return { name: "users.show", params: { id: this.id as unknown as string } };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async setSetting(name: string, value: any): Promise<ISettings> {
    if (typeof value !== "string") {
      value = JSON.stringify(value);
    }
    return await Api.update<ISettings>("login", "setting", {
      name,
      value,
    }).then((x) => (this.settings = x));
  }

  async saveSettings(): Promise<ISettings> {
    return await Api.post<ISettings>("login/settings", {
      settings: JSON.stringify(this.settings),
    }).then((x) => (this.settings = x));
  }

  toString(): string {
    return this.name;
  }
}
