/* eslint-disable no-console */

import { register } from "register-service-worker";
import store from "@/store";
import { Actions } from "@/store/types";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    /* ready () {
      console.log(
        'App is being served from cache by a service worker.'
      );
    }, */
    registered(registration) {
      store.dispatch(Actions.SET_SERVICE_WORKER_REGISTRATION, registration);
      setInterval(() => {
        registration.update().then().catch();
      }, 10000);
    },
    /* cached () {
    },
    updatefound () {
    }, */
    updated() {
      store.dispatch(Actions.INIT_UPDATE);
    },
    /* offline () {
    }, */
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
