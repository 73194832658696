import axios, { AxiosPromise, AxiosResponse } from "axios";
import { ApiConfig, CollectionResponse } from "@/api/types";
import { ApiError } from "@/api/ApiError";
import store from "@/store";
import { Actions, Modules, Mutations } from "@/store/types";
import { AuthMutations } from "@/store/modules/auth/types";
import { VueRouter } from "@/main";
import { Tenant } from "@/api/types/Tenant";

const API_URL = process.env.NODE_ENV === "development" ? process.env.VUE_APP_API_URL : window.configuration.API;
axios.interceptors.response.use(
  (r) => r,
  (error) => {
    const err = new ApiError(error);
    if (err.config?.noAutoHandling) {
      return Promise.reject(err);
    }
    if (err.isSystemError) {
      store.dispatch(Actions.SET_SERVER_ERROR, err).catch();
      VueRouter.push({
        name: "server-error",
      }).catch(() => {
        store.commit(Mutations.SET_APP_ERROR, err);
      });
    } else {
      if (err.status === 401) {
        Api.removeToken();
        store.commit(Modules.AUTH + "/" + AuthMutations.SET, null);
        VueRouter.push({
          name: "account.login",
          query: {
            redirect: VueRouter.currentRoute.fullPath,
            reason: err.status + "",
          },
        }).catch(() => {
          store.commit(Mutations.SET_APP_ERROR, err);
        });
      } else if (err.status) {
        if ([403, 404].includes(err.status)) {
          store.commit(Mutations.SET_ERROR, err);
        } else if (err.status >= 500) {
          store.commit(Mutations.SET_APP_ERROR, err);
        }
      }
    }
    return Promise.reject(err);
  }
);

export class ApiClass {
  init(): void {
    axios.defaults.baseURL = API_URL;
    this.initAuth();
    this.initTenant();
  }

  index<T>(
    resource: string,
    params: Record<string, string> = {},
    config: ApiConfig = {}
  ): Promise<CollectionResponse<T>> {
    config.returnPlain = true;
    return this.wrap<CollectionResponse<T>>(axios.get<CollectionResponse<T>>(resource, { ...config, params }), true);
  }

  indexAll<T>(
    resource: string,
    params: Record<string, string> = {},
    config: ApiConfig = {}
  ): Promise<CollectionResponse<T>> {
    return this.fetchEverything(this.index(resource, params, config), {
      ...config,
      params,
    });
  }

  get<T>(resource: string, slug: string | number = "", config: ApiConfig = {}): Promise<T> {
    return this.wrap<T>(axios.get<T>(slug === "" ? resource : `${resource}/${slug}`, config));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  post<T>(resource: string, params: any, config: ApiConfig = {}): Promise<T> {
    return this.wrap<T>(axios.post<T>(`${resource}`, params, config));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  patch<T>(resource: string, params: any, config: ApiConfig = {}): Promise<T> {
    return this.wrap<T>(axios.patch<T>(`${resource}`, params, config));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  patchIndex<T>(
    resource: string,
    data: any,
    params: Record<string, string> = {},
    config: ApiConfig = {}
  ): Promise<CollectionResponse<T>> {
    config.returnPlain = true;
    return this.wrap<CollectionResponse<T>>(
      axios.patch<CollectionResponse<T>>(resource, data, {
        ...config,
        params,
      }),
      true
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  update<T>(resource: string, slug: string | number, params: any, config: ApiConfig = {}): Promise<T> {
    return this.wrap<T>(axios.put<T>(`${resource}/${slug}`, params, config));
  }

  delete(resource: string, slug: string | number): Promise<AxiosResponse<unknown>> {
    return axios.delete(`${resource}/${slug}`);
  }

  download(
    url: string,
    params: any = {},
    method:
      | "get"
      | "GET"
      | "delete"
      | "DELETE"
      | "head"
      | "HEAD"
      | "options"
      | "OPTIONS"
      | "post"
      | "POST"
      | "put"
      | "PUT"
      | "patch"
      | "PATCH"
      | "purge"
      | "PURGE"
      | "link"
      | "LINK"
      | "unlink"
      | "UNLINK"
      | undefined = "GET"
  ): Promise<AxiosResponse<any>> {
    return axios.request({
      url,
      method,
      params,
      responseType: "blob",
    });
  }

  setToken(token: string): void {
    window.localStorage.setItem("token", token);
    this.initAuth();
  }

  getToken(): string | null {
    return window.localStorage.getItem("token");
  }

  removeToken(): void {
    window.localStorage.removeItem("token");
    this.initAuth();
  }

  setTenant(tenant: Tenant): void {
    window.localStorage.setItem("tenant", tenant.id as string);
    this.initTenant();
  }

  getTenant(): string | null {
    return window.localStorage.getItem("tenant");
  }

  removeTenant(): void {
    window.localStorage.removeItem("tenant");
    this.initTenant();
  }

  private fetchEverything<T>(
    promise: Promise<CollectionResponse<T>>,
    config: ApiConfig = {}
  ): Promise<CollectionResponse<T>> {
    return new Promise((resolve, reject) => {
      promise
        .then(async (data) => {
          const _data = data;
          if (!_data.links || (_data.links && _data.links.next == null)) {
            resolve(_data);
          } else {
            while (_data.links && _data.links.next != null) {
              await axios
                .get<CollectionResponse<T>>(_data.links.next, config)
                .then(({ data }) => {
                  _data.data = _data.data.concat(data.data);
                  _data.meta = data.meta;
                  _data.links = data.links;
                })
                .catch((err) => reject(err));
            }
            resolve(_data);
          }
        })
        .catch((err) => reject(err));
    });
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  hasTenant(): boolean {
    return !!this.getTenant();
  }

  private initAuth() {
    if (this.getToken() !== undefined) {
      axios.defaults.headers.common.Authorization = `Bearer ${this.getToken()}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }

  private initTenant() {
    if (this.getTenant() !== undefined) {
      axios.defaults.headers.common["vioxide-tenant"] = this.getTenant() ?? "";
    } else {
      delete axios.defaults.headers.common["vioxide-tenant"];
    }
  }

  private wrap<T>(promise: AxiosPromise<T>, singleWrap = false) {
    return new Promise<T>((resolve, reject) => {
      promise
        .then((x) => {
          const config = x.config as ApiConfig;
          const data = singleWrap ? x.data : (x.data as any).data;
          if (config.cast) {
            resolve(config.cast(data));
          } else if (config.castColl) {
            data.data = config.castColl(data.data);
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((x) => reject(x));
    });
  }
}

const instance: ApiClass = new ApiClass();
export const Api = instance;
export const SearchTimeout = 1000;
export default instance as ApiClass;
