
import Component, { mixins } from "vue-class-component";
import ToastStateMixin from "@/mixins/store/ToastStateMixin";

@Component
export default class Toasts extends mixins(ToastStateMixin) {
  margin(i: number): string {
    return i * 60 + 8 + "px";
  }
}
