/* eslint-disable camelcase */
import { ActionTree, Dispatch, DispatchOptions, GetterTree, Module, MutationTree } from "vuex";
import { Modules, RootState } from "@/store/types";
import { ToastActions, ToastGetters, ToastMutations } from "@/store/modules/toast/types";
import i18n from "@/plugins/i18n";

export class Toast {
  showing = true;
  timeout = 3000;
  color: string | null;
  message: string;
  handler!: number;

  constructor(message: string, color: string | null = null, timeout = 3000) {
    this.message = message;
    this.color = color;
    this.timeout = timeout;
  }
}

export function createToast(translationKey: string, values = {}, color: string | null = null, timeout = 5000) {
  return new Toast(i18n.t(translationKey, values) as string, color, timeout);
}

export async function dispatchToast(dispatch: Dispatch, toast: Toast, options: DispatchOptions = { root: true }) {
  return await dispatch(Modules.TOAST + "/" + ToastActions.ADD_TOAST, toast, options);
}

export interface ToastState {
  toasts: Array<Toast>;
}

const state: ToastState = {
  toasts: [],
};

const getters: GetterTree<ToastState, RootState> = {
  [ToastGetters.TOASTS](state) {
    return state.toasts;
  },
};
const mutations: MutationTree<ToastState> = {
  [ToastMutations.ADD_TOAST](state, toast: Toast) {
    state.toasts = state.toasts.concat(toast);
  },
  [ToastMutations.REMOVE_TOAST](state, toast: Toast) {
    const i = state.toasts.indexOf(toast);
    state.toasts.splice(i, 1);
  },
};
const actions: ActionTree<ToastState, RootState> = {
  [ToastActions.ADD_TOAST]({ commit, dispatch }, toast: Toast) {
    commit(ToastMutations.ADD_TOAST, toast);
    toast.handler = setTimeout(() => dispatch(ToastActions.CLOSE_TOAST, toast), toast.timeout + 10);
  },
  [ToastActions.CLOSE_TOAST]({ commit }, toast: Toast) {
    commit(ToastMutations.REMOVE_TOAST, toast);
  },
  [ToastActions.START_CLEANUP]({ commit, state }) {
    state.toasts.filter((x) => !x.showing).forEach((x) => commit(ToastMutations.REMOVE_TOAST, x));
  },
};

export const toast: Module<ToastState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
