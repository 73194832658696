/* eslint-disable camelcase */
import { IModel } from "@/api/types/Model";
import { EmptyMetaAttribute, IMetaAttribute } from "@/api/types/MetaAttribute";
import { Location as LocationRouter } from "vue-router/types/router";
import { TenantUserEndpointClass } from "@/api/Endpoints/TenantUserEndpoint";
import { CollectionResponse } from "@/api/types/index";
import { History, IHaveHistory } from "@/api/types/History";
import { TenantEndpoint } from "@/api/Endpoints/TenantEndpoint";

export interface ITenant extends IModel, IHaveHistory {
  name: string;
  description: string;
  legal_name: string;
  address: string;
  postcode: string;
  town: string;
  meta: IMetaAttribute;
}

export class Tenant implements ITenant {
  created_at!: Date;
  name!: string;
  id!: string;
  legal_name!: string;
  description!: string;
  address!: string;
  postcode!: string;
  town!: string;
  updated_at!: Date;
  meta = EmptyMetaAttribute();
  users: TenantUserEndpointClass | null = null;

  constructor(input?: ITenant) {
    if (input) {
      Object.assign(this, input);
      this.users = new TenantUserEndpointClass(this);
    }
  }

  history(params: Record<string, string> = {}, all = false): Promise<CollectionResponse<History>> {
    return TenantEndpoint.history(this, params, {}, all);
  }

  historyName(): string {
    return this.name;
  }

  getLink(): LocationRouter {
    return { name: "tenants.show", params: { id: this.id as string } };
  }

  toString(): string {
    return this.name;
  }
}
