import { EndpointWithIndex } from "@/api/Endpoints/Endpoint";
import { User } from "@/api/types/User";
import { ApiConfig, CollectionResponse } from "@/api/types";
import { History } from "@/api/types/History";

export class UserEndpointClass extends EndpointWithIndex<User> {
  constructor() {
    super("users");
    this.cast = (x) => new User(x);
    this.castColl = (a) => a.map((x) => new User(x));
  }

  history(
    user: User,
    params: Record<string, string> = {},
    config: ApiConfig = {},
    all = false
  ): Promise<CollectionResponse<History>> {
    return this.indexFunc(`${this.resource}/${user.id}/history`, params, config, all);
  }
}

export const UserEndpoint = new UserEndpointClass();
