import { Modules } from "@/store/types";
import { TenantActions, TenantGetters } from "@/store/modules/tenant/types";
import Vue from "vue";
import { Tenant } from "@/api/types/Tenant";

export default Vue.extend({
  computed: {
    hasTenantSelected(): boolean {
      return this.$store.getters[Modules.TENANT + "/" + TenantGetters.SELECTED];
    },
    selectedTenant(): Tenant {
      return this.$store.getters[Modules.TENANT + "/" + TenantGetters.TENANT];
    },
    isTenantLoading(): boolean {
      return this.$store.getters[Modules.TENANT + "/" + TenantGetters.LOADING];
    },
    tenants(): Array<Tenant> {
      return this.$store.getters[Modules.TENANT + "/" + TenantGetters.TENANTS];
    },
  },
  methods: {
    tenantFetch() {
      return this.$store.dispatch(Modules.TENANT + "/" + TenantActions.FETCH);
    },
    async tenantSelect(tenant: Tenant | null) {
      return await this.$store.dispatch(Modules.TENANT + "/" + TenantActions.SELECT, tenant);
    },
  },
});
