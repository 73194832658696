import Vue from "vue";
import VueI18n from "vue-i18n";
import { DateTimeFormats, Messages } from "@/lang/de";
import axios from "axios";
import moment from "moment";

Vue.use(VueI18n);

const locale = process.env.NODE_ENV === "development" ? process.env.VUE_APP_I18N_LOCALE : window.configuration.LOCALE;
moment.locale(locale);
export const i18n = new VueI18n({
  locale,
  fallbackLocale:
    process.env.NODE_ENV === "development"
      ? process.env.VUE_APP_I18N_FALLBACK_LOCALE
      : window.configuration.FALLBACK_LOCALE,
  messages: {
    de: Messages,
  },
  dateTimeFormats: DateTimeFormats,
});
const loadedLanguages = ["de"];

function setI18nLanguage(lang: string) {
  moment.locale(locale);
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  // eslint-disable-next-line no-unused-expressions
  document?.querySelector("html")?.setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang: string): Promise<string> {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const messages = await import(/* webpackChunkName: "lang-[request]" */ "@/lang/" + lang + ".js");
  i18n.setLocaleMessage(lang, messages.default);
  i18n.setDateTimeFormat(lang, messages.DateTimeFormat);
  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}

export default i18n;
